import React from "react"
import Layout from "../components/layout"
import referralsheroimg from "../images/referrals-heroimg.png"
import referralsfeatureone from "../images/referrals-feature-one.png"
import referralsfeaturetwo from "../images/referrals-feature-two.png"
import Finalctablock from "../components/Finalctablock"

const referrals = () => {
  return (
    <Layout>
      <div className="container">
        <section className="referrals-hero">
          <div className="referrals-herotxt">
            <h1>Get new customers by accelerating word of mouth</h1>
            <p>
              Let more customers refer your business to their family and friends
              with InsideHero referral generation.
            </p>
            <a href="">
              <button className="btn btn-main btn-hero">BOOK DEMO</button>
            </a>
          </div>

          <img src={referralsheroimg} className="referrals-heroimg" alt="" />
        </section>
      </div>

      <div className="container feature-stripe-cont">
        <div className="solutionstripefeaturetop"></div>
        <section className="features-grid referrals-feature-one">
          <img src={referralsfeatureone} alt="" />
          <div className="featurestxt">
            <h3>Have more of your customers do your marketing for you</h3>
            <p>
              Chances are you passively rely on word of mouth for most of your
              business. With InsideHero, pro-actively increase word of mouth by
              creating custom referral campaigns, turning your happiest
              customers into your newest stream of revenue.
            </p>
          </div>
        </section>
      </div>

      <div className="container feature-stripe-cont">
        <section className="features-grid referrals-feature-two">
          <div className="featurestxt">
            <h3>Incentivize, share and track social campaigns</h3>
            <p>
              With rewards you can incentivize your happiest customers to share
              their experience with family and friends, whilst enticing new
              customers with a first time reward. Track referral campaigns all
              from one dashboard.
            </p>
          </div>
          <img src={referralsfeaturetwo} alt="" />
        </section>
        <div className="solutionstripefeaturebottom"></div>
      </div>

      <div className="messaging-stats-grad">
        <div className="container">
          <section className="messaging-stats-section">
            <h2>
              Your business wins when your customers do your marketing for you
            </h2>

            <div className="messaging-stats-block">
              <div className="message-stats stat-one">
                <h3>4x</h3>
                <p className="message-stat-subtitle">more likely to buy </p>
                <p>
                  83% of consumers trust referrals from people they know and are
                  more likely to buy when referred by a friend.
                </p>
              </div>

              <div className="message-stats stat-two">
                <h3>16x</h3>
                <p className="message-stat-subtitle">higher lifetime value</p>
                <p>
                  Referred customers are more loyal and spend 13.2% more than
                  non-referred customers.
                </p>
              </div>

              <div className="message-stats stat-three">
                <h3>37%</h3>
                <p className="message-stat-subtitle">higher retention rate</p>
                <p>
                  Customers who are referred churn less often and are 4-5x more
                  likely to refer new customers.
                </p>
              </div>
            </div>
          </section>
        </div>
      </div>

      <div className="container feature-stripe-cont">
        <div className="container">
          <h2>Features</h2>
          <section className="product-features">
            <div className="product-features-point product-features-point-one">
              <h4>Notifications</h4>
              <p>
                Be alerted when recieve a new referral so you can respond
                immediately.
              </p>
            </div>

            <div className="product-features-point product-features-point-two">
              <h4>Time-saving templates</h4>
              <p>
                Send personalised referral invites or make it easier with
                customisable templates.
              </p>
            </div>

            <div className="product-features-point product-features-point-three">
              <h4>Analytics</h4>
              <p>
                Track referral revenue generated, invites sent and referrals by
                employee.
              </p>
            </div>

            <div className="product-features-point product-features-point-four">
              <h4>Referral Management</h4>
              <p>Manage referrals, coupon codes, and referrees owed.</p>
            </div>
          </section>
          <div className="productstripefeaturebottom-right"></div>
        </div>
      </div>

      <div className="container feature-stripe-cont">
        <Finalctablock />
      </div>
    </Layout>
  )
}

export default referrals
